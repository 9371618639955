import { ViewProps } from '../../../../common/types';
import { Typography, styled } from '@mui/material';
import { CtrlComboSelect } from '../../../../components/controls/CtrlComboSelect';
import { CtrlCheckbox } from '../../../../components/controls/CtrlCheckbox';
import { CtrlDateField } from '../../../../components/controls/CtrlDateField';
import { theme } from '../../../../theme';
import { ArrowRightIcon, CloseIcon, InfoIcon, PlusIcon } from '../../../../theme/icons';
import { CtrlButton } from '../../../../components/controls/CtrlButton';
import { ReferralModel } from '../../../ReferralModel';
import { IProcedure } from './DiagnosticState';
import { DiagnosticModel, physicianFollowUpDateRange, sectionSystemCode } from './DiagnosticModel';
import { memo, useCallback, useEffect } from 'react';
import { CtrlIconButton } from '../../../../components/controls/CtrlIconButton';
import CustomRowsComboSelect, { TCustomRowsColum } from '../../../../components/controls/CustomRowsComboSelect';

export interface DiagnosticsProps extends ViewProps<ReferralModel> {}
export interface ProcedureProps extends ViewProps<DiagnosticModel> {
    label: string;
    procedure: IProcedure;
    onRemove?: (procedureId: string) => void;
    getError: (fieldName: string) => string | undefined;
}

const ruleOutColumns: TCustomRowsColum[] = [
    { label: 'Code', key: 'value', span: 2 },
    { label: 'Type', key: 'type', span: 2 },
    { label: 'Description', key: 'label', span: 8, withTooltip: 'label' },
];

const diagnosticProceduresColumns: TCustomRowsColum[] = [
    { label: 'Package Name', key: 'label', span: 10, withTooltip: 'label' },
    { label: 'Service Type', key: 'type', span: 2 },
];

function ProcedureComponent(props: ProcedureProps) {
    const { procedure, label, onRemove, getError, model } = props;
    const isOtherProcedure = procedure.product?.value === 'Other';

    const handleChange = useCallback(
        (fieldName: string) => (value?: any) => {
            model.handleProcedureChange(procedure, fieldName, value);
        },
        [model, procedure],
    );

    return (
        <div className={props.className}>
            <div className="procedure-section">
                <div className="label-section">
                    <div className="text-area-label">{label}</div>
                    {onRemove && (
                        <CtrlIconButton title="Remove" size="small" onClick={() => onRemove(procedure.id)}>
                            <CloseIcon />
                        </CtrlIconButton>
                    )}
                </div>
                <div className="fields-container full-width">
                    <div className="span-3">
                        <CtrlComboSelect
                            label="Procedure"
                            required
                            value={procedure.product}
                            onChange={model.handleProductChange(procedure)}
                            selectFrom={model.getProducts}
                            error={getError('product.value')}
                        />
                    </div>
                    <div className="span-3">
                        <CtrlComboSelect
                            label="Body Part"
                            value={procedure.bodyPart}
                            onChange={model.handleBodyPartChange(procedure)}
                            selectFrom={procedure.bodyParts}
                            disabled={!procedure.product || isOtherProcedure || !procedure.bodyParts?.length}
                            required={Boolean(procedure.bodyParts?.length)}
                            error={getError('bodyPart.value')}
                        />
                    </div>
                    <div className="span-3">
                        <CtrlComboSelect
                            label="Body Side"
                            value={procedure.bodySide}
                            onChange={handleChange('bodySide')}
                            selectFrom={procedure.bodySides}
                            disabled={!procedure.product || isOtherProcedure || !procedure.bodySides?.length}
                            required={Boolean(procedure.bodySides?.length)}
                            error={getError('bodySide.value')}
                        />
                    </div>
                    <div className="span-3">
                        <CtrlComboSelect
                            label="Procedure Details"
                            value={procedure.procedureOption}
                            onChange={handleChange('procedureOption')}
                            selectFrom={procedure.procedureOptions}
                            disabled={!procedure.product || isOtherProcedure || !procedure.procedureOptions?.length}
                        />
                    </div>
                </div>
                <div className="fields-container full-width">
                    <div className="span-12">
                        <CustomRowsComboSelect
                            label="Other Procedure"
                            value={procedure.otherProcedure}
                            onChange={handleChange('otherProcedure')}
                            onChangeNative={model.getDiagnosticProcedures(procedure.id, 'diagnosticProcedures')}
                            selectFrom={procedure.diagnosticProcedures}
                            disabled={!isOtherProcedure}
                            onClose={() => handleChange('diagnosticProcedures')()}
                            columns={diagnosticProceduresColumns}
                            required={procedure.product?.value === 'Other'}
                            error={getError('otherProcedure.value')}
                        />
                    </div>
                </div>
                <div className="fields-container full-width">
                    <div className="span-6">
                        <CustomRowsComboSelect
                            label="Diagnosis / Rule-Out 1"
                            value={procedure.ruleOut1}
                            onChange={handleChange('ruleOut1')}
                            onChangeNative={model.getRuleOuts(procedure.id, 'ruleOuts1')}
                            selectFrom={procedure.ruleOuts1}
                            disabled={!procedure.product}
                            onClose={() => handleChange('ruleOuts1')()}
                            columns={ruleOutColumns}
                            onGetLabel={(option) => [option?.value, option?.label].filter((v) => v).join(' - ')}
                        />
                    </div>
                    <div className="span-6">
                        <CustomRowsComboSelect
                            label="Diagnosis / Rule-Out 2"
                            value={procedure.ruleOut2}
                            onChange={handleChange('ruleOut2')}
                            onChangeNative={model.getRuleOuts(procedure.id, 'ruleOuts2')}
                            selectFrom={procedure.ruleOuts2}
                            disabled={!procedure.product}
                            onClose={() => handleChange('ruleOuts2')()}
                            columns={ruleOutColumns}
                            onGetLabel={(option) => [option?.value, option?.label].filter((v) => v).join(' - ')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const ProcedureComponentMemo = memo(ProcedureComponent);

function DiagnosticComponent(props: DiagnosticsProps) {
    const { model } = props;
    const diagnosticModel = model.diagnosticModel;
    const state = diagnosticModel.state.use();

    const { handleControlChange, addProcedure, removeProcedure } = diagnosticModel;

    useEffect(() => {
        if(!state.productsLoaded){
            diagnosticModel.init();
        }
    }, [diagnosticModel, state]);

    const getProcedureError = useCallback(
        (idx: number, fieldName: string) => diagnosticModel.getErrorMessage(`procedures[${idx}].${fieldName}`),
        [diagnosticModel],
    );

    return (
        <div className={props.className}>
            {state.procedures.map((procedure, idx) => (
                <ProcedureComponentMemo
                    key={`procedure-${idx}`}
                    label={`Procedure ${idx + 1}`}
                    procedure={procedure}
                    model={diagnosticModel}
                    onRemove={state.procedures?.length > 1 ? removeProcedure : undefined}
                    getError={(fieldName) => getProcedureError(idx, fieldName)}
                />
            ))}

            <div>
                <CtrlButton disabled={state.procedures.length > 4} endIcon={<PlusIcon />} onClick={addProcedure}>
                    Add Procedure
                </CtrlButton>
            </div>

            <div className="general-info-row">
                <div className="span-1">
                    <CtrlCheckbox label="Rush" value={state.isRush} onChange={handleControlChange('isRush')} />
                </div>
                <div className="span-3">
                    <CtrlDateField
                        label="Referring Physician Follow Up"
                        value={state.physicianFollowUpDate}
                        onChange={handleControlChange('physicianFollowUpDate')}
                        onTextChange={handleControlChange('physicianFollowUpDateString')}
                        minDate={physicianFollowUpDateRange().from}
                        maxDate={physicianFollowUpDateRange().to}
                        error={diagnosticModel.getErrorMessage('physicianFollowUpDate')}
                        width={280}
                    />
                </div>
                <div className="span-6">
                    <Typography className="requests-label" variant="body1" color={theme.app.neutralColor.textMain}>
                        Referring Physician Requests
                    </Typography>
                    <div className="requests-checkboxes">
                        <CtrlCheckbox
                            label="Films"
                            value={state.filmsRequested}
                            onChange={handleControlChange('filmsRequested')}
                        />
                        <CtrlCheckbox
                            label="CDs"
                            value={state.cdsRequested}
                            onChange={handleControlChange('cdsRequested')}
                            style={{ marginLeft: 24 }}
                        />
                    </div>
                </div>
            </div>
            <div className="notes-row">
                <InfoIcon />
                <div className="span-12">
                    Note: Any additional procedures, pre-screening concerns, and/or scheduling instructions can be
                    indicated in the special instructions section.
                </div>
            </div>
            <div className="continue-button-row">
                <CtrlButton
                    endIcon={<ArrowRightIcon />}
                    onClick={() => {
                        model.validateSection(sectionSystemCode, diagnosticModel);
                        model.validateSection('Attachments', model.attachmentsModel);
                    }}
                >
                    Continue
                </CtrlButton>
            </div>
        </div>
    );
}

export const Diagnostic = styled(DiagnosticComponent, { name: 'Diagnostics' })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 6),
    padding: theme.spacing(0, 4, 4),
    '.procedure-section': {
        borderRadius: 12,
        border: '1px solid var(--neutral-border-main, #DEDDE3)',
        padding: '20px 24px',
        marginBottom: '24px',
        width: '65%',
        background: theme.app.neutralColor.backgroundSuperLight,
    },
    '.label-section': {
        fontWeight: 600,
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '.full-width': {
        width: '100%',
    },
    '.general-info-row': {
        display: 'flex',
        width: '65%',
        columnGap: theme.spacing(4),
        alignItems: 'center',
        marginTop: theme.spacing(9),
    },
    '.requests-label': {
        marginTop: theme.spacing(-6),
        marginBottom: 6,
    },
    '.notes-row': {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        columnGap: theme.spacing(1),
    },
}));
