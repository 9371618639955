import _ from 'lodash';
import { ChangeableModel } from '../../../../model/model';
import { TResourceValue, valuesManager } from '../../../../services/valuesManager/valuesManager';
import { IDiagnosticState, IProcedure } from './DiagnosticState';
import { FieldValidator, SectionSystemCode, TRange } from '../../../../common/types';
import { validateDateField, validateRequiredField } from '../../../../common/validationHelpers';
import { showConfirmationDialog } from '../../../../components/dialogs/ConfirmationDialog';
import { ReferralModel, referralModel } from '../../../ReferralModel';
import dayjs from 'dayjs';
import { fixCollectionFieldsErrorsOrder } from '../../utils';

export const sectionSystemCode: SectionSystemCode = 'DiagnosticServices';

export const physicianFollowUpDateRange = (): TRange<Date> => {
    return {
        from: dayjs(new Date()).startOf('day').toDate(),
        to: dayjs(new Date()).add(1, 'year').endOf('year').toDate(),
    };
};

export const diagnosticValidators: FieldValidator[] = [
    {
        fieldName: 'physicianFollowUpDate',
        fieldLabel: 'Physician Follow Up Date',
        validateFunctions: [
            validateDateField(
                () => referralModel.diagnosticModel.state.get().physicianFollowUpDateString,
                physicianFollowUpDateRange,
            ),
        ],
    },
];

export class DiagnosticModel extends ChangeableModel<IDiagnosticState> {
    async init() {
        await this.loadDiagnosticProducts();
    }

    loadBodyParts = async (procedureId: string) => {
        const productId = this.getProcedure(procedureId)?.product?.id;
        let bodyParts = productId ? await valuesManager.getBodyParts(productId) : [];

        this.updateProcedure(procedureId, (p) => ({
            ...p,
            bodyPart: undefined,
            bodySide: undefined,
            bodyParts: bodyParts,
        }));
    };

    loadBodySides = async (procedureId: string) => {
        const bodyPartId = this.getProcedure(procedureId)?.bodyPart?.id;
        let bodySides = bodyPartId ? await valuesManager.getBodyPartModifiers(bodyPartId) : [];

        this.updateProcedure(procedureId, (p) => ({
            ...p,
            bodySide: undefined,
            bodySides: bodySides,
        }));
    };

    loadProcedureOptions = async (procedureId: string) => {
        const productId = this.getProcedure(procedureId)?.product?.id;
        let procedureOptions = productId ? await valuesManager.getProcedureOptions(productId) : [];

        this.updateProcedure(procedureId, (p) => ({
            ...p,
            procedureOption: undefined,
            procedureOptions: procedureOptions,
        }));
    };

    handleProcedureChange = (procedure: IProcedure, fieldName: string, value: any) => {
        this.clearProcedureFieldError(procedure, fieldName);
        this.updateProcedure(procedure.id, (p) => ({ ...p, [fieldName]: value }));
    };

    handleProductChange = (procedure: IProcedure) => async (value?: TResourceValue) => {
        let differentProduct = false;

        this.updateProcedure(procedure.id, (p) => {
            differentProduct = p.product?.value !== value?.value;
            return {
                ...p,
                product: value,
                ruleOut1: !value?.value ? undefined : p.ruleOut1,
                ruleOut2: !value?.value ? undefined : p.ruleOut2,
                otherProcedure: value?.value !== 'Other' ? undefined : p.otherProcedure,
            };
        });

        if (differentProduct) {
            this.clearProcedureFieldError(procedure, 'product.value');
            this.clearProcedureFieldError(procedure, 'bodyPart.value');
            this.clearProcedureFieldError(procedure, 'bodySide.value');
            this.clearProcedureFieldError(procedure, 'otherProcedure.value');

            await this.loadBodyParts(procedure.id);
            await this.loadBodySides(procedure.id);
            await this.loadProcedureOptions(procedure.id);
        }
    };

    handleBodyPartChange = (procedure: IProcedure) => async (value?: TResourceValue) => {
        let differentBodyPart = false;

        this.updateProcedure(procedure.id, (p) => {
            differentBodyPart = p.bodyPart?.value !== value?.value;
            return {
                ...p,
                bodyPart: value,
                bodySide: differentBodyPart ? undefined : p.bodySide,
            };
        });

        if (differentBodyPart) {
            this.clearProcedureFieldError(procedure, 'bodyPart.value');
            this.clearProcedureFieldError(procedure, 'bodySide.value');

            await this.loadBodySides(procedure.id);
        }
    };

    getProducts = (): TResourceValue[] => {
        return this.state.get().products || [];
    };

    getRuleOuts = (procedureId: string, toFieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const icdCode = e.target.value;
        this.debouncedGetRuleOuts(icdCode, procedureId, toFieldName);
    };

    getDiagnosticProcedures =
        (procedureId: string, toFieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const procedureName = e.target.value;
            const productLine =
                referralModel.state.get().customization === 'Navigere'
                    ? 'NavigereDiagnosticServices'
                    : 'DiagnosticServices';
            this.debouncedGetDiagnosticProcedures(procedureName, productLine, procedureId, toFieldName);
        };

    addProcedure = () => {
        this.state.update((s) => {
            s.procedures.push({
                id: _.uniqueId(),
            });
        });
    };

    removeProcedure = async (procedureId: string) => {
        const procedure = this.getProcedure(procedureId);

        const hasChanges =
            procedure?.product?.value ||
            procedure?.bodyPart?.value ||
            procedure?.bodySide?.value ||
            procedure?.otherProcedure?.value ||
            procedure?.otherProcedure?.value ||
            procedure?.ruleOut1?.value ||
            procedure?.ruleOut2?.value;

        const shouldRemove =
            hasChanges &&
            (await showConfirmationDialog({
                title: 'Are you sure?',
                message: 'Please confirm that you want to remove this procedure.',
            }));

        if (shouldRemove === 'Cancel') {
            return;
        }

        this.state.update((s) => {
            s.errors = _.filter(
                s.errors,
                (error) =>
                    !error.fieldName?.startsWith(`procedures[${s.procedures.findIndex((p) => p.id === procedureId)}]`),
            );
            s.procedures = s.procedures.filter((s) => s.id !== procedureId);

            fixCollectionFieldsErrorsOrder('procedures', 'Procedure', s);
        });
    };

    getSummaryFields = () => {
        const procedures = this.state.get().procedures;
        return new Map([
            [
                'Procedure',
                procedures
                    .filter((p) => p.product)
                    .map((p) =>
                        p.product?.value === 'Other'
                            ? p.otherProcedure?.label
                            : [p.product?.label, p.bodySide?.label, p.bodyPart?.label, p.procedureOption?.label]
                                  .filter((v) => v)
                                  .join(' '),
                    )
                    .join('\n'),
            ],
        ]);
    };

    validate(referralModel: ReferralModel): void {
        const procedures = this.state.get().procedures;
        this.validators = [
            ...diagnosticValidators,
            ...procedures.map((_, idx) => this.getProcedureValidators(idx)).flat(),
        ];

        super.validate(referralModel);
    }

    override getAdditionalNotes(withSpecialInstructions: boolean = true) {
        const state = this.state.get();

        let notes: [string, string][] = [];

        const products = state.procedures.map((p) => p.product?.value).filter((p) => p);

        const note = [
            referralModel.getGeneralInstructions(),
            this.formatAdditionalNotes([], sectionSystemCode, withSpecialInstructions),
        ];

        (products.length ? products : ['']).forEach((product) => {
            notes.push([product ?? '', note.filter(n => n).join(';')]);
        });

        return new Map<string, string>(notes);
    }

    private readonly getFieldValidator = (
        index: number,
        field: string,
        label: string,
        validateFn: (procedure: IProcedure, value: any) => string | undefined,
    ): FieldValidator => {
        return {
            fieldName: `procedures[${index}].${field}`,
            fieldLabel: `Procedure ${index + 1} - ${label}`,
            validateFunctions: [(value: any) => validateFn(this.state.get().procedures[index], value)],
        };
    };

    private readonly getProcedureValidators = (index: number): FieldValidator[] => {
        return [
            this.getFieldValidator(index, 'product.value', 'Product', (_, v) => validateRequiredField(v)),
            this.getFieldValidator(index, 'bodyPart.value', 'Body Part', (p, v) =>
                Boolean(p.bodyParts?.length) ? validateRequiredField(v) : undefined,
            ),
            this.getFieldValidator(index, 'bodySide.value', 'Body Side', (p, v) =>
                Boolean(p.bodySides?.length) ? validateRequiredField(v) : undefined,
            ),
            this.getFieldValidator(index, 'otherProcedure.value', 'Other Procedure', (p, v) =>
                p.product?.value === 'Other' ? validateRequiredField(v) : undefined,
            ),
        ];
    };

    private getProcedure(procedureId: string) {
        return this.state.get().procedures.find((p) => p.id === procedureId);
    }

    private updateProcedure(procedureId: string, updateFn: (procedure: IProcedure) => IProcedure) {
        this.state.update((s) => {
            s.procedures = s.procedures.map((p) => {
                if (p.id === procedureId) {
                    return updateFn(p);
                }
                return p;
            });
        });
    }

    private async loadDiagnosticProducts() {
        const diagnosticCategory = valuesManager.resourceValues.productCategories?.find(
            (c) => c.value === 'DiagnosticServices',
        );
        const diagnosticProducts = await valuesManager.getProducts(diagnosticCategory?.id ?? 0);

        this.state.update((s) => {
            s.productsLoaded = diagnosticProducts.length > 0;
            s.products = [...diagnosticProducts, { label: 'OTHER (ENTER BELOW)', value: 'Other' }];
        });
    }

    private debouncedGetRuleOuts = _.debounce(async (icdCode, procedureId, toFieldName) => {
        if (!icdCode || icdCode.length < 2) {
            return;
        }

        const ruleOuts = await valuesManager.getIcdCodes(icdCode, 'DiagnosticServices');
        this.state.update((s) => {
            const procedure = s.procedures.find((p) => p.id === procedureId);
            if (procedure) {
                _.set(procedure, toFieldName, ruleOuts);
            }
        });
    }, 500);

    private debouncedGetDiagnosticProcedures = _.debounce(async (procedureName, productLine, procedureId, toFieldName) => {
        if (!procedureName || procedureName.length < 2) {
            return;
        }

        const procedures = await valuesManager.getDiagnosticProcedures(procedureName, productLine);
        this.state.update((s) => {
            const procedure = s.procedures.find((p) => p.id === procedureId);
            if (procedure) {
                _.set(procedure, toFieldName, procedures);
            }
        });
    }, 500);

    private clearProcedureFieldError(procedure: IProcedure, fieldName: string) {
        this.state.update((s) => {
            const procedureTemplate = `procedures[${s.procedures.findIndex((p) => p.id === procedure.id)}]`;
            const errors = s.errors.find(
                (err) =>
                    err.fieldName === `${procedureTemplate}.${fieldName}` ||
                    err.fieldName === `${procedureTemplate}.${fieldName}.value`,
            );
            if (errors) {
                errors.errors = [];
            }
        });
    }
}
