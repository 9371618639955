import resourceValues from '../../data/resourceValues.json';
import { apiClient } from '../../api/order';
import { TBodyPart, TBodyPartModifier, TDiagnosticProcedure, TInjuryType, TProcedureOption, TProduct, TProductCategory, TRuleOut } from './responseTypes';
import { TGlobalState } from '../../model/state';

export type TResourceValue = {
    value: string;
    label: string;
    freeText?: boolean;
    id?: number | string;
    description?: string;
};

export type TResourceValueProductCategory = TResourceValue & {
    productLineCode?: string;
    productLineId?: number | string;
};

export interface ResourceValues {
    states: TResourceValue[];
    languages: TResourceValue[];
    transportationTypes: TResourceValue[];
    transportationWheelchairOptions: TResourceValue[];
    transportationServices: TResourceValue[];
    appointmentTypes: TResourceValue[];
    medicalSupplies?: TResourceValue[];
    respiratorySupplies?: TResourceValue[];
    homeHealthServices?: TResourceValue[]; 
    productCategories?: TResourceValueProductCategory[];
    attachmentTypes: TResourceValue[];
    languageCertifications: TResourceValue[];
    addressTypes: TResourceValue[];
    referralReasonValues: TResourceValue[];
    doctorReferralForValues?: TResourceValue[];
    visitPurposeValues: TResourceValue[];
    dentalReferralForValues?: TResourceValue[];
    dentalVisitPurposeValues: TResourceValue[];
}

class ValuesManager extends TGlobalState<ResourceValues> {
    constructor() {
        super(resourceValues)
    }

    get resourceValues() {
        return this.get();
    }

    async getProductCategories(): Promise<TResourceValueProductCategory[]> {
        const response = await apiClient.get()?.callApi<TProductCategory[]>('api/values/productCategories', 'GET');
        return response?.success
            ? (response.data || []).map((category) => ({
                  label: category.name,
                  value: category.systemCode,
                  id: category.productCategoryId,
                  productLineCode: category.productLineCode,
                  productLineId: category.productLineId, 
              }))
            : [];
    }

    async getBodyParts(productId: number | string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TBodyPart[]>(`api/values/bodyParts?productId=${productId}`, 'GET');

        return response?.success
            ? (response.data || []).map((bodyPart) => ({
                  label: bodyPart.name,
                  value: bodyPart.systemCode,
                  id: bodyPart.bodyPartId,
              }))
            : [];
    }

    async getBodyPartModifiers(bodyPartId: number | string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TBodyPartModifier[]>(`api/values/bodyPartModifiers?bodyPartId=${bodyPartId}`, 'GET');
        return response?.success
            ? (response.data || []).map((modifier) => ({
                  label: modifier.name,
                  value: modifier.name,
                  id: modifier.bodyPartModifierId,
              }))
            : [];
    }

    async getInjuryTypes(bodyPartId: number | string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TInjuryType[]>(`api/values/injuryTypes?bodyPartId=${bodyPartId}`, 'GET');
        return response?.success
            ? (response.data || []).map((injury) => ({
                  label: injury.injuryTypeName,
                  value: injury.systemCode,
                  id: injury.injuryTypeId,
              }))
            : [];
    }

    async getProducts(productCategoryId: number | string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TProduct[]>(`api/values/products?productCategoryId=${productCategoryId}`, 'GET');

        return response?.success
            ? (response.data || []).map((product) => ({
                  label: product.name,
                  value: product.systemCode,
                  id: product.productId,
              }))
            : [];
    }

    async getProcedureOptions(productId: number | string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TProcedureOption[]>(`api/values/procedureOptions?productId=${productId}`, 'GET');

        return response?.success
            ? (response.data || []).map((product) => ({
                  label: product.name,
                  value: product.systemCode,
                  id: product.procedureOptionId,
              }))
            : [];
    }

    async getIcdCodes(icdCode: string, productLineSystemCode?: string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TRuleOut[]>(
                `api/values/ruleOuts?icdCode=${icdCode}${
                    productLineSystemCode ? `&productLineSystemCode=${productLineSystemCode}` : ''
                }&limit=100`,
                'GET',
            );

        return response?.success
            ? (response.data || []).map((product) => ({
                  label: product.serviceType,
                  value: product.code,
                  id: product.index,
                  type: 'ICD-10'
              }))
            : [];
    }

    async getDiagnosticProcedures(procedureName: string, productLine: string): Promise<TResourceValue[]> {
        const response = await apiClient
            .get()
            ?.callApi<TDiagnosticProcedure[]>(
                `api/values/diagnosticProcedures?procedureName=${procedureName}&productLine=${productLine}&limit=100`,
                'GET',
            );

        return response?.success
            ? (response.data || []).map((product) => ({
                  label: product.packageName,
                  value: product.index,
                  id: product.index,
                  type: product.serviceType,
              }))
            : [];
    }

    async getDoctorReferralForValues(): Promise<TResourceValue[]> {
        const response = await apiClient
        .get()
        ?.callApi<TResourceValue[]>(
            'api/values/doctorService',
            'GET'
        );

        return response?.success
            ? (response.data || [])
            : [];
    }

    async getDentalReferralForValues(): Promise<TResourceValue[]> {
        const response = await apiClient
        .get()
        ?.callApi<TResourceValue[]>(
            'api/values/dentalService',
            'GET'
        );

        return response?.success
            ? (response.data || [])
            : [];
    }

    async loadProductCategories(): Promise<void> {
        const productCategories = await this.getProductCategories();
        this.update(s => {
            s.productCategories = productCategories;
        });
    }

    async loadReferralForValues(): Promise<void> {
        if (this.resourceValues.doctorReferralForValues?.length) return;

        const referralForValues = await this.getDoctorReferralForValues();
        this.update(s => {
            s.doctorReferralForValues = referralForValues;
        });
    }

    async loadDentalReferralForValues(): Promise<void> {
        const dentalReferralForValues = await this.getDentalReferralForValues();
        this.update(s => {
            s.dentalReferralForValues = dentalReferralForValues;
        });
    }
}

export const valuesManager = new ValuesManager();
